<template>
  <div>
      <OpenCartEditMain/>
  </div>
</template>

<script>
import OpenCartEditMain from '@/views/ProduceManagement/openManage/TabChild/OpenCartEditMain.vue'
export default {
    name:'OpenCartEdit',
    components:{
        OpenCartEditMain
    }
}
</script>

<style lang="scss" scoped>

</style>