<template>
  <el-card>
    <!-- <editHeader :isShow="isShow" :statusShow="false" :btn="btn" /> -->

    <el-form
      :disabled='true'
      ref="editCardFormRef"
      :model="editCardForm"
      label-width="120px"
      :rules="editCardFormRules"
    >
      <el-row :gutter="20">
         <el-col :span="8">
          <el-form-item label="产品编号：" size="small" prop="prod_no">
            <el-select
              clearable
              v-model="editCardForm.prod_no"
              placeholder="请选择产品编号"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="订单产品总数：" size="small" prop="order_no">
            <el-input v-model="editCardForm.order_no" clearable></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="开卡人：" size="small" prop="order_no">
            <el-input v-model="editCardForm.stff_name" clearable></el-input>
          </el-form-item>
        </el-col>
       
      </el-row>
      <el-row :gutter="20" class="vd_mt_20">
      
        <!-- <el-col :span="8">
          <el-form-item label="个数：" size="small" prop="podr_prod_num">
            <el-input
              v-model="editCardForm.podr_prod_num"
              @input="
                editCardForm.podr_prod_num = helper.keepTNum(
                  editCardForm.podr_prod_num
                )
              "
              clearable
              @change="podrNumChange"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="刷卡：" size="small">
            <el-input
              :disabled="
                editCardForm.podr_prod_num && editCardForm.rounds ? false : true
              "
              v-model="brushCard"
              @input="brushCard = helper.keepTNum(brushCard)"
              @keyup.native.enter="brushCardKeyup"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import editHeader from "@/views/component/editHeader";
import { openAPI } from "@/api/modules/open";
import { podtAPI } from "@/api/modules/podt";
import { get, post } from "@/api/request";
export default {
  name: "OpenCartEditMain",
  components: {
    // editHeader,
  },
  data() {
    return {
      editCardForm: {
        prod_no: "",
        order_no: "",
        rounds: "",
        podr_prod_num: "",
        stff_name:''
      },
      rfidArr: [],
      userInfo: {},
      brushCard: "",
      rfid_no_list: [],
      isShow: true,
      btn: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      editCardFormRules: {
        prod_no: [
          { required: true, message: "请输入产品编号", trigger: "blur" },
        ],
        order_no: [
          { required: true, message: "请输入订单编号", trigger: "blur" },
        ],

        rounds: [{ required: true, message: "请输入轮次", trigger: "blur" }],
        podr_prod_num: [
          { required: true, message: "请输入个数", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.rfid_no) {
         this.getCartInfo(this.$route.query.rfid_no)
      }
    },
    getCartInfo(rfid){
      get(openAPI.get_rfid_by_id,{rfid_id:rfid})
      .then(res =>{
        console.log(res);
       if(res.data.code!==0){
         return this.$message.error(res.data.msg)
       }
      //  this.editCardForm.podr_prod_num = res.data.data.form.podr_prod_num
       this.editCardForm.prod_no = res.data.data.form.podr_no
       this.brushCard = res.data.data.form.rfid_no
       this.editCardForm.stff_name = res.data.data.form.stff_name
       this.btn = res.data.data.btn 
      

      }).catch(res =>{
        this.$message.error(res.data.msg)
      })
    },
    getCardInfo() {
      this.editCardForm.rfid_no = this.$route.query.rfid_no;
    },

    podrNumChange() {
      this.addCardForm.podr_prod_num = Number(this.addCardForm.podr_prod_num);
    },
    roundsChange() {
      this.addCardForm.rounds = Number(this.addCardForm.rounds);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: red;
}
.vd_mt_20 {
  margin-top: 20px;
}
</style>